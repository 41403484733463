import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import ResourceArticlesAll from '../components/BlogContent/ResourceArticlesAll'

const Resources = ({ data }) => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Resources"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Resources"
      />
      <ResourceArticlesAll />
      <Footer />
    </Layout>
  )
}



export default Resources
