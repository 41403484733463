import React from "react"
import YAMLData from "../../content/resources.yml" 

const ResourceArticles = (props) => {

  return (
    <section className="blog-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="row">
              {YAMLData.resources.slice(0, props.limit).map(item => {
                return (
                  <div className="col-lg-4 col-md-6">
                    <div className="single-blog-post">
                      <div className="post-image">
                        { item.image && 
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <img src={item.image} alt="blog" />
                        </a>
                        }
                      </div>
                      <div className="post-content">
                        <ul className="post-meta d-flex justify-content-between align-items-center">
                          <li>{item.author}</li>
                        </ul>
                        <h3>
                          <a href={item.url} target="_blank" rel="noreferrer">
                            {item.title}
                          </a>
                        </h3>
                        <p> {item.subtitle}</p>
                       
                        <div className="widget-area">
                          <div className="widget widget_tag_cloud">
                            <h3 className="widget-title"></h3>

                            <div className="tagcloud"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ResourceArticles
