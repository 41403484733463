import React from "react"
import starIcon from "../../assets/images/technical-support.png"
import ResourceArticles from "./ResourceArticles"

const ResourceArticlesAll = (props) => {
  return (
    <section className="blog-area pt-100 pb-70">
      <div className="container">
      <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="blog" />
            Resources
          </span>
          <h2>Worth Sharing</h2>
          <p>If you want to share an article, just send me a message and I will add it here.</p>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <ResourceArticles limit={100} tag={props.tag} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ResourceArticlesAll
